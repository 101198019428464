.slider {
    @apply static;
}
.slider__container {
    @apply relative;
    @apply overflow-visible;
}

.slider-main-container {
    @apply relative;
    @apply overflow-visible;
}

.slider__slides {
    @apply relative;
    @apply overflow-hidden;
    @apply py-0;
    @apply px-20;
}

.slider_arrow {
    @apply absolute
    top-1/2 z-10;
    @apply cursor-pointer;
    @apply -translate-y-1/2;
}

.slider_arrow .arrow {
    @apply block;
    @apply border-r-2;
    @apply border-b-2;
    @apply border-slider-arrows;
    @apply p-10;
    @apply w-0;
    @apply h-0;
}

.slider_arrow_prev {
    @apply -left-30;
}

.slider_arrow_prev .arrow {
    @apply rotate-[135deg];
}

.slider_arrow_next {
    @apply -right-30;
}

.slider_arrow_next .arrow {
    @apply rotate-[-45deg];
}

.swiper-button-disabled {
    @apply cursor-default;
}

.swiper-button-disabled .arrow {
    @apply opacity-40;
}

.swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}
