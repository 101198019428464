:root {
    --white: #ffffff;

    --smoke-white: #ededed;
    --purple: #bf348b;
    --dark-gray: #414141;
    --light-gray: #f3f4f5;
    --very-light-gray: #cdcdcd;
    --pale-gray: #e5e7eb;
    --gray: #6f6f6f;
    --cloud-gray: #c5c6d0;
    --light-blue: #4747c9;
    --dark-blue: #032942;
    --red: #ef1010;
    --overlay-grey: rgba(0, 0, 0, 0.65);
    --orange: #ec6f57;
    --sapphire-blue: #09375c;
    --maroon: #6d3049;
    --dark-blue-gray: #344459;

    --font-body: var(--font-sans);
    --font-header: var(--font-raleway-black);
    --font-secondary: var(--font-sans);

    --font-thin: 300;
    --font-regular: 400;
    --font-bold: 600;
    --font-medium: 500;

    --font-size-base: 16px;
    --font-size-reg: 18px;
    --font-size-l: 22px;
    --font-size-md: 14px;
    --font-size-sm: 12px;
    --line-height-base: 125%;

    --primary: var(--dark-gray);
    --overlay: var(--overlay-grey);

    /* Buttons */
    --btn-radius: 3px;
    --btn-lg-radius: 5px;
    --btn-xl-radius: 10px;
    --btn-font-size: var(--font-size-base);
    --btn-lg-font-size: var(--font-size-reg);
    --btn-disabled: var(--pale-gray);
    --btn-primary-bg: var(--dark-gray);
    --btn-primary-text: var(--white);
    --btn-primary-weight: var(--font-bold);
    --btn-primary-hover: var(--gray);

    --btn-secondary-bg: var(--white);
    --btn-secondary-border: var(--dark-gray);
    --btn-secondary-weight: var(--font-bold);
    --btn-secondary-text: var(--dark-gray);
    --btn-secondary-disabled: var(--pale-gray);

    /* Accents */
    --accent-1: var(--dark-blue);

    --accent-2: var(--dark-blue);

    /* Header */
    --header-bg: var(--white);
    --header-height-md: 140px;
    --header-height: 90px;
    --icons-height: 24px;
    --icons-height-md: 40px;
    --icons-label-weight: var(--font-bold);
    --icons-label-size-md: 15px;
    --icons-label-size: 10px;
    --account-hover: var(--maroon);
    --search-hover: var(--orange);
    --cart-indicator: var(--orange);
    --cart-hover: var(--dark-blue);
    --menu-hover: var(--sapphire-blue);
    --menu-bg: var(--sapphire-blue);

    /*Cart */
    --close-icon: var(--dark-blue);
    --promo-price: var(--orange);
    --discounted-price-size: var(--font-size-md);
    --discounted-price-font: var(--font-body);
    --discounted-price-weight: var(--font-regular);

    /* menu */
    --menu-font: var(--font-secondary);
    --menu-cat-color: var(--dark-blue);
    --menu-cat-size: 21px;
    --menu-cat-size-md: calc(21px + (27 - 21) * ((100vw - 744px) / (1920 - 744)));
    --menu-subcat-color: var(--dark-gray);
    --menu-subcat-size: 18px;
    --menu-subcat-size-md: calc(18px + (23 - 18) * ((100vw - 744px) / (1920 - 744)));
    --menu-weight: var(--font-bold);
    --menu-shadow: -80px 0px 150px 0px rgba(0, 0, 0, 0.65);

    /* Footer */
    --footer-bg: var(--dark-gray);
    --footer-link-color: var(--white);

    /* Input */
    --input-bg: var(--white);
    --input-border: var(--dark-gray);
    --input-error: var(--red);
    --input-text: var(--dark-gray);
    --label-font: var(--font-body);
    --label-weight: var(--font-bold);
    --label-text: var(--dark-gray);
    --input-disabled: var(--very-light-gray);
    --input-placeholder: var(--cloud-gray);

    /* Checkout step */
    --checkout-step-bg: var(--white);
    --checkout-step-border-color: var(--very-light-gray);
    --checkout-delegates: var(--dark-blue);

    /* Checkout footer */
    --checkout-page-bg: var(--smoke-white);
    --checkout-footer-bg: var(--white);
    --checkout-footer-bg-mobile: var(--pale-gray);

    /* Checkout */
    --checkout-border: var(--pale-gray);

    /* Tile */
    --tile-primary: var(--white);
    --tile-accent: var(--dark-blue);
    --tile-radius: 20px;
    --tile-font-family: var(--font-header);
    --tile-product-name-font-family: var(--font-body);
    --tile-font-weight: var(--font-medium);
    --tile-product-name-font-weight: var(--font-bold);
    --tile-category: var(--dark-blue);

    /* Slider */
    --arrows-border: var(--dark-blue);
    --slider-title: var(--dark-blue);

    /* Search */
    --search-bg: var(--light-gray);
    --search-accent: var(--dark-gray);
    --search-text: var(--dark-gray);
    --search-text-hover: var(--white);
    --search-icon: var(--orange);

    /* Account */
    --hub-link-bg: var(--orange);
    --hub-link-color: var(--white);
    --chip-individual: var(--orange);
    --chip-business: var(--dark-blue-gray);
    --register-note: var(--light-blue);

    /* Learner Page */
    --learner-accent: var(--orange);
    --learner-accent-1: var(--light-blue);
}

.order-info-width {
    max-width: calc(100% - 136px);
}
