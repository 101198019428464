div.PhoneInput {
    @apply relative;
    @apply mt-10;
}

div.PhoneInput.error .PhoneInputInput {
    @apply border-input-error;
}

select.PhoneInputCountrySelect {
    @apply w-80;
    @apply absolute;
    @apply top-10;
    @apply opacity-0;
    @apply z-10;
    @apply pl-12;
}

div.PhoneInputCountryIcon {
    @apply w-30;
    @apply absolute;
    @apply top-12;
    @apply left-12;
    @apply pointer-events-none;
}

div.PhoneInputCountrySelectArrow {
    @apply block;
    @apply w-8;
    @apply h-[8px];
    @apply border-solid;
    @apply border-e;
    @apply border-b;
    @apply rotate-45;
    @apply absolute;
    @apply left-53;
    @apply top-17;
    @apply border-input-border;
    @apply z-0;
}

input.PhoneInputInput {
    @apply p-10;
    @apply pl-72;
    @apply w-full;
    @apply border-solid;
    @apply border;
    @apply border-input-border;
    @apply rounded-md;
}

input.PhoneInputInput:disabled {
    @apply text-input-disabled;
    @apply cursor-not-allowed;
}

input.PhoneInputInput:focus {
    @apply outline-none;
}

div.PhoneInput.error input.PhoneInputInput:focus {
    @apply border-input-border;
}
